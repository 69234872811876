import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const TabNav = ({ className, ...rest }) => (
  <nav
    className={twMerge(
      'flex flex-nowrap border-x-0 border-b border-t-0 border-neutral-high-400 dark:border-neutral-low-300',
      className
    )}
    {...rest}
  />
);

TabNav.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default TabNav;
