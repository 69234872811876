/**
 * TODO: default titles can be internalized to improve accessibility and DX
 */
import PropTypes from 'prop-types';
import { createElement } from 'react';
import { twMerge } from 'tailwind-merge';

import SpriteSheet from 'shopper/components/SpriteSheet';

const SIZES = ['size1', 'size2', 'size3', 'size4', 'size5'];
const HTML_MARKUP_OPTIONS = { svg: 'svg', button: 'button', a: 'a' };
const ROLES = ['presentation', 'img'];

const ICON_SIZE_MAP = {
  size1: 'min-w-6 size-6',
  size2: 'min-w-5 size-5',
  size3: 'min-w-4 size-4',
  size4: 'min-w-3 size-3',
  size5: 'min-w-2 size-2',
};

const getSize = (size) =>
  ICON_SIZE_MAP?.[size] ? ICON_SIZE_MAP[size] : ICON_SIZE_MAP[SIZES[1]];

const Icon = ({
  as = 'svg',
  'aria-hidden': ariaHidden = false,
  className,
  name,
  role = 'presentation',
  size = 'size2',
  title = null,
  ...rest
}) => {
  const currSize = getSize(size);

  const Component = (
    <SpriteSheet
      aria-hidden={ariaHidden}
      className={twMerge(
        'fill-current inline-flex text-neutral-low-300 dark:text-neutral-high-100',
        currSize,
        className
      )}
      icon={name}
      role={role}
      sheet="spritemap"
      title={title}
    />
  );

  if (!HTML_MARKUP_OPTIONS[as] || as === 'svg') {
    return Component;
  }

  return createElement(HTML_MARKUP_OPTIONS[as], { ...rest }, Component);
};

Icon.propTypes = {
  /**
   * Changes the HTML type of icon, can be choosing one of `svg`, `button`
   * and `a`
   */
  as: PropTypes.oneOf(Object.keys(HTML_MARKUP_OPTIONS)),
  /**
   * Indicates whether the icon is available as an accessible element.
   */
  'aria-hidden': PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Name of icon that to be rendered
   */
  name: PropTypes.string.isRequired,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Change the representation of icon in window. Default is `presentation`
   */
  role: PropTypes.oneOf(ROLES),
  /**
   * Set icon size
   */
  size: PropTypes.oneOf(SIZES),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Set the icon title for accessibility
   */
  title: PropTypes.string,
};

export default Icon;
