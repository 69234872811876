import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Box from 'shopper/components/Box';

import Image from 'components/Image';
import UserAvatar from 'components/UserAvatar';

import useDialog from 'hooks/useDialog';

import { useUser } from 'providers/UserProvider';

import TopbarSearch from '../TopbarSearch';

import APP from 'constants/app';
import LINKS from 'constants/links';

const UserDialog = dynamic(() => import('./UserDialog'), {
  ssr: false,
});

const GA_EVENT_CATEGORY = 'dropdown_mobile';
const LOGO_PATH = `${APP.LOCAL_IMAGE_PATH}/general/logos/promobit-logo-icon.svg`;

const TopbarMobile = ({ className }) => {
  const router = useRouter();
  const { LoggedInUser } = useUser();
  const { showDialog } = useDialog();

  const onAvatarClick = () => {
    showDialog(UserDialog, {
      gaEventCategory: GA_EVENT_CATEGORY,
    });
  };

  const onLogoClick = () => {
    if (router.asPath === LINKS.HOME) {
      window.location.reload();
      return;
    }

    router.push(LINKS.HOME);
  };

  return (
    <div className={className}>
      <Box className="flex-row items-center gap-x-4 rounded-none p-4 shadow-[0px_0px_10px_0px_rgba(87,87,87,0.2)] dark:border-b dark:border-neutral-low-300">
        <button className="size-10" onClick={onLogoClick}>
          <Image
            alt="Promobit"
            className="lg:hidden"
            height={40}
            loading={null}
            sizes="(max-width: 1024px) 40px, 1px"
            src={LOGO_PATH}
            srcSet={`${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w, ${LOGO_PATH} 40w`}
            width={40}
          />
        </button>
        <TopbarSearch />
        <button className="size-10" onClick={onAvatarClick}>
          <UserAvatar
            size="size4"
            userName={LoggedInUser?.userName}
            userPhoto={LoggedInUser?.userPhoto}
          />
        </button>
      </Box>
    </div>
  );
};

TopbarMobile.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default TopbarMobile;
