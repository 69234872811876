var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8ef5b7b95bd5b0674deeb7a08f3150cf859c6422"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/**
 * This file configures the initialization of Sentry on the browser. The config
 * you add here will be used whenever a page is visited.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/
 *
 * After migrate to Next.JS v13, implement `thirdPartyErrorFilterIntegration`
 * Sentry config to improve filtering of 3p errors:
 * https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
 */
import { extraErrorDataIntegration } from '@sentry/integrations';
import { init } from '@sentry/nextjs';

const INTERNAL_GOOGLE_ADS_DIRECTORY = 'pageFold';

/**
 * @type {import('@sentry/nextjs').BrowserOptions}
 */
init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,
  integrations: [
    // TODO: check if this integration is the cause to various console errors
    // started being captured by Sentry in final of October
    // captureConsoleIntegration(),
    extraErrorDataIntegration(),
  ],
  // send only 30% of transactions
  tracesSampleRate: 0.3,
  // send only 50% of captured errors
  sampleRate: 0.5,
  // Reference:
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    // References:
    // - https://github.com/getsentry/sentry-javascript/issues/3440
    // - https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured',
    /**
     * Common error for blocked third party resources by extensions like uBO.
     * Examples:
     * - https://promobit-organization.sentry.io/issues/5938873434/?project=5581922&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=14d&stream_index=8
     * - https://promobit-organization.sentry.io/issues/5705614745/?project=5581922&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=14d&stream_index=7
     * - https://promobit-organization.sentry.io/issues/5786924055/?project=5581922&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=14d&stream_index=17
     */
    'Failed to fetch',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: https://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See https://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See https://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Avast extension error
    '_avast_submit',
    // Temporarily ignore Firefox bug with Fetch API https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
    'TypeError: NetworkError when attempting to fetch resource.',
    // Temporarily ignore NextJS router error with trailingSlash config https://github.com/vercel/next.js/issues/39638#issuecomment-1221104901
    /Invariant: attempted to hard navigate to the same URL/i,
    // This error to be resolved after Safari implements the WebPush API, what should be happen in your next versions https://webkit.org/blog/12945/meet-web-push/
    /This browser doesn't support the API's required to use the Firebase SDK/i,
    // Sentry report error that shouldn't be logged in production
    'Failed to load resource: the server responded with a status of 429 (Too Many Requests)',
    // Caused by AdBlock extensions that uses "Better Js Popunder Script"
    /Cannot redefine property: BetterJsPop/i,
    /require is not defined/i,
    // Caused by browsers extensions, like:
    // - Grammarly
    // - Katalon Recorder
    /Identifier 'originalPrompt' has already been declared/i,
    /Error response received for message <get-frame-manager-configuration>/i,
    /jwplayer is not defined/i,
    /Unexpected token '<'/i,
    /Unexpected token '>'/i,
    /GH is not defined/i,
    // Common Google Ads errors
    /\(tag\/js\/gpt\)/i,
    /gpt\/pubads/i,
    /null is not an object (evaluating 'CleverCore.DOM.getByID(v).style')/i,
    /ResizeObserver loop completed with undelivered notifications./i,
    // Competing company extension issue
    "Failed to execute 'define' on 'CustomElementRegistry': the name \"cuponomia-store-bar\" has already been used with this registry",
    // Metamask's extension issue
    'Object captured as promise rejection with keys: code, message',
    // status codes
    /429/i,
    /401/i,
    // Errors related to Google Ads
    /\[GPT\]/i,
    "Cannot read properties of null (reading 'addService')",
    'NS_ERROR_NOT_INITIALIZED',
    /FBNav/i,
    "Cannot destructure property 'address' of '(intermediate value)' as it is undefined.",
    /SYNC\.JS:/i,
    // Crawler errors
    /bis_frame_id/i,
    /Script called 1 times/i,
    /Pop-up candidates final number:/i,
    /Pop-up candidates final number :/i,
    /fSCsetInTicket/i,
    /bis_log_from_js@/i,
    /found unique frames/i,
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Common urls denied by adblockers
    /securepubads\.g\.doubleclick\.net/i,
    /pagead2\.googlesyndication\.com/i,
    /tag\/js\/gpt/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /ingest\.sentry\.io/i,
    /http:\/\/lopmenedo.com/i,
  ],
  /**
   * The Google Ads has several internal issues, one of these was the un-control
   * of errors of ads, making Sentry capturing several noisy errors that are
   * invisible to final user.
   *
   * Before Sentry send the error and your stack-trace to server we attempt to
   * verify if this error is originated from a Google Ad. This validation isn't
   * 100% assertive but it possibly reduces the amount of noisy errors processed
   * by Sentry.
   */
  beforeSend: (event) => {
    for (let i in event.exception?.values?.[0]?.stacktrace?.frames) {
      if (
        event.exception?.values?.[0]?.stacktrace?.frames[i]?.filename.includes(
          INTERNAL_GOOGLE_ADS_DIRECTORY
        )
      )
        return null;
    }

    return event;
  },
});
